/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Logoy from './img/logo-yloo.png';

import Fob from './img/fob.jpg';
import { Link } from 'react-router-dom';
class Home extends React.Component {
    constructor() {
        super();
        this.state = {
        }
    }

    render() {
        return (
            <div className="App">

                <footer className="bg-dark text-center text-white"  >
                    <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
                        <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", height: 70, width: "100%" }} >
                            <a className="text-reset"><img src={Logoy} style={{ height: 60, width: 'auto' }} alt='logo' /></a>

                        </div>
                    </section>
                    <section className="">
                        <div className="container text-center text-md-start mt-5">
                            <div className="row mt-3">
                                <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                                    <h6 className="text-uppercase fw-bold mb-4" style={{ color: 'white' }}>
                                        تواصل معنا
                                    </h6>
                                    <p style={{ color: 'white', fontSize: 14 }}>  العراق بغداد المنصور

                                    </p>
                                    <p style={{ color: 'white', fontSize: 14 }}>

                                        info@gra-jumla.com
                                    </p>

                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", height: "50px", width: "100%", backgroundColor: "#ffb424" }}>

                                    <p style={{ color: 'white', fontSize: 12 }}>
                                        <Link to="/ShippingPolicy" className="text-reset">سياسة التوصيل</Link>
                                    </p>
                                    <p style={{ color: 'white', fontSize: 12 }}>
                                        <Link to="/PrivacyPolicy" className="text-reset">سياسة خاصة</Link>
                                    </p>
                                    <p style={{ color: 'white', fontSize: 12 }}>
                                        <Link to="/TermsOfService" className="text-reset">شروط الخدمة</Link>
                                    </p>
                                    <p style={{ color: 'white', fontSize: 12 }}>
                                        <Link to="/RefundPolicy" className="text-reset">سياسات الاستبدال</Link>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </section>
                </footer>
            </div>
        );
    }
}

export default Home;
