
import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './assets/css/main.css';
import { Carousel } from 'react-responsive-carousel';

import moment from 'moment';



import { Pane, Dialog } from 'evergreen-ui'

import Logo from './img/banner1.webp'
import Check from './img/check.png'
import axios from 'axios';
import { Form, Button, Spinner } from 'react-bootstrap';
import Component from '@reactions/component'

const host = "https://api.enfirad.com/"
class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      color: false,
      colo2: false,
      get_color2: '',
      name: '',
      gov: '',
      city: '',
      phone: '',
      size: '',
      count: 1,
      s40: false,
      s1: false,
      s2: false,
      s3: false,
      s4: false,
      more: false,
      counts: [],
      items: [],
      price: 60000,

      item: {},
      photos: []
    }

    // let value = document.getElementById('countvalue').innerHTML
    // console.log(value);

  }


  numberWithCommas(x) {
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x))
      x = x.replace(pattern, "$1,$2");
    return x;
  }
  componentDidMount() {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const product = urlParams.get('item')



    let arry1 = localStorage.getItem('items')

    if (arry1) {


      var orders = JSON.parse(arry1);
      const items = orders.filter((e) => e.item_id == product)
      const item = items[0]


      if (item) {
        if (item.item_id == product) {




          var now = moment(new Date()); //todays date
          var end = moment(moment(item.date).format('YYYY-MM-DD')); // another date
          var duration = moment.duration(now.diff(end));
          var days = duration.asDays();


          if (days < 3) {

            window.location.href = "/onprocess";
          }


        }
      }


    }

    // if (check_item == product) {

    //   var now = moment(new Date()); //todays date
    //   var end = moment(moment(check_date).format('YYYY-MM-DD')); // another date
    //   var duration = moment.duration(now.diff(end));
    //   var days = duration.asDays();
    //   console.log(days)

    //   console.log(days < 3)

    //   if (days < 3) {
    //     console.log('here');

    //     window.location.href = "/thankyou.html";
    //   }


    // }



    axios.get(`https://api.enfirad.com/dashbord/shop/items?item_id=${product}`)
      .then(resp => {

        console.log('p', resp.data.items[0].photos[0].photo);
        let photo = ""
        if (resp.data.items[0].photos.length > 0) {
          photo = resp.data.items[0].photos[0].photo
        }
        this.setState({ item: resp.data.items[0], photos: photo, price: resp.data.items[0].price })



      });



  }
  check_phone(phone) {
    if (phone.length != 11) {
      window.alert('يرجى التأكد من رقم الهاتف')

      return true
    }
    let result = phone.substring(0, 4);

    if (result[[0]] != 0) {
      window.alert('يرجى التأكد من رقم الهاتف')

      return true
    }
    if (result[[1]] != 7) {

      window.alert('يرجى التأكد من رقم الهاتف')

      return true
    }
    if (result[2] != 7 && result[2] != 8 && result[2] != 9 && result[2] != 5) {

      window.alert('يرجى التأكد من رقم الهاتف')

      return true
    }
    if (result[[3]] > 6) {
      window.alert('يرجى التأكد من رقم الهاتف')

      return true
    }




    return false
  }
  submit() {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const item_id = urlParams.get('item')

    let bg = ""

    let s_date = ""
    let utm_source = ""
    let utm_medium = ""
    let utm_content = ""

    if (urlParams.get('bg')) {
      bg = urlParams.get('bg')


    }
    if (urlParams.get('utm_source')) {
      utm_source = urlParams.get('utm_source')
    }
    if (urlParams.get('s_date')) {
      s_date = urlParams.get('s_date')
    }

    if (urlParams.get('utm_medium')) {
      utm_medium = urlParams.get('utm_medium')
    }

    if (urlParams.get('utm_content')) {
      utm_content = urlParams.get('utm_content')
    }




    document.getElementById('spinnerdiv').style.display = 'flex'
    document.getElementById('submitbtn').style.display = "none"
    let name = document.getElementById('name').value;
    let phone = document.getElementById('phone').value;
    let city = document.getElementById('city').value;
    let gov = document.getElementById('gov').value;



    if (!name) {
      window.alert('يرجى ادخال الاسم')

      document.getElementById('spinnerdiv').style.display = 'none'
      document.getElementById('submitbtn').style.display = "flex"

      return -1;


    }
    if (this.check_phone(phone)) {

      document.getElementById('spinnerdiv').style.display = 'none'
      document.getElementById('submitbtn').style.display = "flex"
      return -1;
    }
    if (!gov || gov === "none") {
      window.alert('يرجى ادخال المحافظة')
      document.getElementById('spinnerdiv').style.display = 'none'
      document.getElementById('submitbtn').style.display = "flex"
      return -1;
    }
    if (!city) {
      window.alert('يرجى ادخال المنطقة')
      document.getElementById('spinnerdiv').style.display = 'none'
      document.getElementById('submitbtn').style.display = "flex"
      return -1;
    }


    axios.post(host +
      `users/web/v2/orders/new/`, {
      name,
      phone,
      city,
      gov,
      item_id,
      ip: "",
      bg,
      utm_source,
      s_date,
      utm_medium, 
      utm_content
    })
      .then(response => {


        let arry1 = localStorage.getItem('items')

        if (arry1) {
          var oldOrders = JSON.parse(arry1);

          let obj = {
            item_id: item_id,
            date: new Date()
          }
          oldOrders.push(obj)
          localStorage.setItem('items', JSON.stringify(oldOrders))
        } else {
          let arr = []
          let obj = {
            item_id: item_id,
            date: new Date()
          }
          arr.push(obj)
          localStorage.setItem('items', JSON.stringify(arr))
        }



        window.location.href = "/thankyou.html";

      })
      .catch(error => {
        window.location.href = "/thankyou.html";
      });



  }




  render() {
    return (
      <div className="App">

        <main className="main">
          <div className="page-header breadcrumb-wrap" style={{ display: 'felx', alignItems: "center", }}>





          </div>
          <section className="mt-60 mb-60">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="product-detail accordion-detail">
                    <div className="row mb-50">
                      <div className="col-md-6 col-sm-12 col-xs-12">
                        <div >
                          <img src={host + "files/" + this.state.photos} />
                        </div>
                        {/* 
                        <Carousel autoPlay infiniteLoop >
                       

                          {/* <div>
                            <img src={IMG2} alt='im' />
                          </div> 




                        </Carousel> */}
                        <div className="single-social-share clearfix mt-50 mb-15">
                          <div className="mobile-social-icon wow fadeIn  mb-sm-5 mb-md-0 animated">



                          </div>
                        </div>

                      </div>
                      <div className="col-md-6 col-sm-12 col-xs-12" style={{ textAlign: "right" }} >
                        <div className="detail-info" >
                          <h2 style={{ textAlign: "right" }} className="title-detail"> {this.state.item.name}</h2>
                          <div style={{ width: '100%', disply: 'flex', justfyContent: 'flex-end' }}>

                          </div>
                          <div className="clearfix product-price-cover">
                            <div className="product-price primary-color ">
                              <ins style={{ display: 'flex', flexDirection: 'row-reverse' }}> <div className="text-brand">
                                {this.numberWithCommas(this.state.price) + " "}</div ><div style={{ fontStyle: 'italic', color: "#ffb424", marginRight: "10px" }}>شامل اجور التوصيل </div>  </ins>


                            </div>

                          </div>
                          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                            <img src={Check} width={25} alt="jj" />
                            <h4 style={{ marginRight: 20 }}>الدفع عند الاستلام</h4>
                          </div>
                          <div className="bt-1 border-color-1 mt-15 mb-15"></div>
                          <div className="short-desc mb-30">
                            <p>

                              {this.state.item.body}
                            </p>
                          </div>

                          <div style={{ display: 'flex', marginTop: 15 }}>
                            <input type={"text"} min={1} max={4} style={{ backgroundColor: "#fff", width: '75%', textAlign: 'right', paddingRight: 10, border: "1px #0567d6 solid", borderRadius: 5 }} placeholder="الاسم" id="name" />
                            <h5 style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>الاسم  </h5>

                          </div>
                          <div style={{ display: 'flex', marginTop: 15 }}>
                            {/* <input type={"text"} min={1} max={4} style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} placeholder="المحافظة" id="gov" /> */}

                            <Form.Select id="gov" placeholder='اختر محافظة' style={{ direction: 'rtl', height: 45, width: '75%', textAlign: 'right', paddingRight: 10, border: "1px #0567d6 solid", borderRadius: 5 }} >
                              <option value="none" > اختر محافظة</option>
                              <option value="بغداد">بغداد</option>
                              <option value="بابل">بابل</option>
                              <option value="الأنبار">الأنبار</option>
                              <option value="البصرة">البصرة</option>
                              <option value="دهوك">دهوك</option>s
                              <option value="القادسية">القادسية</option>
                              <option value="ديالى">ديالى</option>
                              <option value="ذي قار">ذي قار</option>
                              <option value="السليمانية">السليمانية</option>
                              <option value="صلاح الدين">صلاح الدين</option>
                              <option value="كركوك">كركوك</option>
                              <option value="كربلاء">كربلاء</option>
                              <option value="المثنى">المثنى</option>
                              <option value="النجف">النجف</option>
                              <option value="نينوى">نينوى</option>
                              <option value="واسط">واسط</option>
                              <option value="ميسان">ميسان</option>
                              <option value="اربيل">اربيل</option>
                              <option value="موصل">موصل</option>
                            </Form.Select>
                            <h5 style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>المحافظة  </h5>

                          </div>
                          <div style={{ display: 'flex', marginTop: 15 }}>
                            <input type={"text"} min={1} max={4} style={{ backgroundColor: "#fff", width: '75%', textAlign: 'right', paddingRight: 10, border: "1px #0567d6 solid", borderRadius: 5 }} placeholder="المدينة" id="city" />
                            <h5 style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>المدينة  </h5>

                          </div>
                          <div style={{ display: 'flex', marginTop: 15 }}>
                            <input type={"number"} min={1} max={4} style={{ backgroundColor: "#fff", width: '75%', textAlign: 'right', paddingRight: 10, border: "1px #0567d6 solid", borderRadius: 5 }} placeholder="الهاتف" id="phone" />
                            <h5 style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>الهاتف  </h5>

                          </div>
                          <div style={{ display: 'flex', marginTop: 15 }}>


                          </div>


                          <br></br>
                          <br></br>
                          <div style={{ display: 'flex', justifyContent: 'center' }}>

                            <div id="spinnerdiv" style={{ display: 'none' }}>
                              <Button variant="primary" style={{ borderRadius: '35px' }} disabled>
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                                ...  تحميل
                              </Button>
                            </div>

                            {/* <button type="submit" id="submitbtn" className="button button-add-to-cart"
  onClick={() => {
    this.submit()
    // console.log(this.state.size, this.state.get_color2);
  }}
>احجز الان</button> */}

                            <Component initialState={{ isShown: false }}>
                              {({ setState, state }) => (
                                <Pane>
                                  <Dialog
                                    isShown={state.isShown}
                                    title="تأكيد"
                                    onCloseComplete={() => setState({ isShown: false })}
                                    confirmLabel="احجز الان"
                                    onConfirm={() => {
                                      this.submit()
                                      setState({ isShown: false })
                                    }}
                                    cancelLabel="رجوع"
                                  >
                                    <p style={{ textAlign: 'end' }}>

                                      هل انت متاكد من عملية الحجز

                                    </p>                                  </Dialog>
                                  <button type="submit" id="submitbtn" style={{ padding: "12px 30px", backgroundColor: "#ffb424", width: "300px", borderRadius: 10 }}
                                    onClick={() => {
                                      setState({ isShown: true })
                                      //    this.submit()
                                      // console.log(this.state.size, this.state.get_color2);
                                    }}
                                  > ارسال الطلب</button>

                                </Pane>
                              )}
                            </Component>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

export default Home;
