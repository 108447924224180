/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './assets/css/main.css';
import Logo from './img/banner1.webp'
import { Link } from "react-router-dom";

class Home extends React.Component {
    constructor() {
        super();
        this.state = {
            color: false,
            colo2: false,
            get_color2: '',
            name: '',
            gov: '',
            city: '',
            phone: '',
            size: '',
            s40: false,
            s1: false,
            s2: false,
            s3: false,
            s4: false,
        }
    }



    render() {
        return (
            <div className="App">

                <main className="main">
                <img style={{width:"100%",height:"auto"}} src={Logo} alt="" />
                    <div class="col-12 col-lg-12" style={{padding:40}}>
                        <div class="single_product_desc">
                            <div class="product-meta-data" style={{textAlign: 'center'}}>

                                <a href="#">
                                    <h3>ساسية التوصيل  </h3>
                                </a>

                            </div>

                            <div class="short_overview my-5">
                                <div style={{textAlign: 'center'}}>

                                    <p style={{textAlign: 'center', fontSize: 20, height: 400}}>
                                        يتم توصيل الطلب خلال 48 ساعة لجميع المحافظات وتكالف التوصيل هي 5000 دينار عراقي ويرجى تدقيق المنتج عند المندوب قبل استلام الطلب   
                                    </p>
                                    
                                    </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

export default Home;
