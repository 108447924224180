/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './assets/css/main.css';
import Logo from './img/banner1.webp'

class Home extends React.Component {
    constructor() {
        super();
        this.state = {
            color: false,
            colo2: false,
            get_color2: '',
            name: '',
            gov: '',
            city: '',
            phone: '',
            size: '',
            s40: false,
            s1: false,
            s2: false,
            s3: false,
            s4: false,
        }
    }



    render() {
        return (
            <div className="App">

                <main className="main">
                    <img style={{ width: "100%", height: "auto" }} src={Logo} alt="" />

                    <div class="col-12 col-lg-12" style={{ padding: 40 }}>
                        <div class="single_product_desc">
                            <div class="product-meta-data" style={{ textAlign: 'center' }}>

                                <a href="#">
                                    <h3>سياسة خاصة </h3>
                                </a>


                            </div>

                            <div class="short_overview my-5">
                                <div style={{ textAlign: 'right' }}>
                                    <h4 style={{ marginBottom: 20 }}>جمع المعلومات الشخصية </h4>
                                    <p style={{ textAlign: 'right', fontSize: 20 }}>
                                        . توضح سياسة الخصوصيّة كيفية جمع واستخدام بياناتك الشخصية (تحت ظروفٍ معينةٍ) وعندما تزور الموقع ، حيث ان الموقع لا يقوم بجمع اي معلومات . الخيارات المتاحة لكَ فيما يتعلّق بجمع البيانات الشخصية قد نحتاج لجمع المعلومات الخاصة بكَ إذا أردت تسجيل طلبية شراءٍ لسلعةٍ من موقعنا وتستخدم هذه البيانات فقط لغرض شحن الطلب اليك وهذه المعلومات هي الاسم والعنوان ورقم الهاتف فقط ويتم حفظ هذه المعلومات لمدة 30 يوم فقط من تاريخ تسجيل طلبية شراءٍ



                                    </p>

                                </div>





                                <div class="short_overview my-5">
                                    <div style={{ textAlign: 'right' }}>
                                        <h4 style={{ marginBottom: 20 }}> البطاقات الائتمانية والحماية من الاحتيال الالكتروني
                                        </h4>
                                        <p style={{ fontSize: '16px' }}>لا يتم استعمال او حفظ اي معلومات حول البطاقات الائتمانية في الموقع الالكتروني حيث ان طريقة الدفع هي دفع عند التوصيل فقط

                                        </p>
                                        <h4 style={{ marginBottom: 20 }}>
                                            Cookies ملفات تعريف الارتباط
                                        </h4>
                                        <p style={{ fontSize: "16px" }}>

                                            لا يشترط منك تفعيل خاصية ملفات تعريف الارتباط وقبولها أثناء زيارة موقعنا على الانترنت


                                        </p>
                                    </div>
                                </div>
                                <div class="short_overview my-5">
                                    <div style={{ textAlign: 'right' }}>
                                        <h4 style={{ marginBottom: 20 }}>اتصل</h4>
                                        <p style={{ textAlign: 'right', fontSize: 20 }}>
                                            لمزيد من المعلومات حول ممارسات الخصوصية لدينا ، إذا كانت لديك أسئلة ، أو إذا
                                            كنت ترغب في تقديم شكوى ، فيرجى الاتصال بنا عبر البريد الإلكتروني على
                                            info@gra-jumla.com

                                        </p>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

export default Home;
